export default function useCommon() {
  const smoothScroll = (element: string) => {
    setTimeout(() => {
      if (!document.querySelector(element)) return
      document.querySelector(element)?.scrollIntoView({
        behavior: 'smooth'
      })
    }, 100)
  }

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  return {
    smoothScroll,
    timeout
  }
}
